// Send QR code to controller, if response is OK, than show PIN inputs
$(document).scannerDetection({
    onComplete: function(barcode){

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/login-with-qr',
            data: {qr: barcode},
            dataType: 'json',
            success: function (response) {
                $('.alert').addClass('d-none');
                $('.spinner-wrapper').addClass('d-none');
                $('.pin-wrapper').removeClass('d-none');
                $('#qr_hash').val(response);
                $('#pin01').focus();
            },
            error: function (error) {
                $('.alert').html(error['responseText']);
                $('.alert').removeClass('d-none');
            }
        });
    }
});

// Check if all input fields is filled, if so, send data to controller, if not, focus on next, or previous field
$('.pin').on('keyup', function (e) {
    isValid = true;

    $('.pin').each(function() {
        if ( $(this).val() === '' )
            isValid = false;
    });

    if(isValid){
        qr = $('#qr_hash').val();
        pin = $('#pin01').val() + $('#pin02').val() + $('#pin03').val() + $('#pin04').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/login-with-qr',
            data: {qr: qr, pin: pin},
            dataType: 'json',
            success: function (response) {
                window.location = '/'
            },
            error: function (error) {
                $('.alert').html(error['responseText']);
                $('.alert').removeClass('d-none');
            }
        });
    }else{
        if ((e.which == 8 || e.which == 46) && $(this).val() =='') {
            $(this).parent().prev().find('input').focus();
            }

        if ($(this).val()) {
            $(this).parent().next().find('input').focus();
        }
    }
})